@import '@/styles/colors';
@import '@/styles/mixins';
@import '@/styles/gaps';
@import '@/styles/typography';

.floatButton {
	height: 48px;
	width: 48px;
	right: 48px;
	bottom: 12px;
	&.mobile {
		right: 12px;
	}
}

.floatButtonBody {
	:global(.ant-float-btn-body) {
		background-color: #5390e9;
		box-shadow: 0 0 6px 0 rgba(83, 144, 233, 0.7);

		&:hover {
			background-color: #06519e;
			box-shadow: 0 0 6px 0 rgba(83, 144, 233, 0.7);
		}
	}
	:global(.ant-float-btn-icon) {
		width: 24px !important;
	}
}

.modal {
	z-index: 2002 !important;
	position: relative;
}

.description {
	color: $grey-text;
	margin-top: $gap-xs;
	margin-bottom: $gap-m;

	@include text-m;
}

.title {
	@include accent-2xl;
}
