@import '@/styles/typography';
@import '@/styles/gaps';
@import '@/styles/colors';
@import '@/styles/mixins';

.localeDropdown {
	z-index: 2003;

	:global(.ant-dropdown-menu-item) {
		padding: $gap-3 $gap-9 !important;
		width: 130px !important;
	}

	:global(.ant-dropdown-menu) {
		padding: $gap-9;
	}

	:global(.ant-dropdown-menu-title-content) {
		color: $grey-text;
		@include accent-s;
		padding: $gap-3 0;
	}

	:global(.ant-dropdown-menu-item-group-title) {
		padding: 0 !important;
		margin-bottom: $gap-xs;
		color: $blue-text !important;
		@include accent-s;
	}
}

.item {
	cursor: pointer;
	padding: $gap-xs $gap-s;
	border-radius: $gap-xs;

	@include accent-m;

	@include lg {
		@include accent-s;
	}

	&:hover {
		background: #ebf2ff;
		color: $blue5;
	}
}
