@import '@/styles/typography';
@import '@/styles/gaps';
@import '@/styles/colors';
@import 'src/styles/mixins';

.header {
	position: sticky;
	z-index: 2001;
	top: 0;
	left: 0;
	width: 100%;
	background: $white;
	box-shadow: 0 0.4rem 1.4rem rgba(0, 0, 0, 0.05);
	gap: $gap-3xl;
	padding: $gap-m $gap-l;

	@include lg {
		gap: $gap-l;
	}

	@include md {
		gap: $gap-m;
		padding: $gap-m $gap-m;
	}

	&Item {
		cursor: pointer;

		@include accent-m;

		@include lg {
			@include accent-s;
		}
	}

	.account,
	&Item {
		cursor: pointer;
		border-radius: $gap-xs;
	}

	.accentItem {
		&:hover {
			background: #ebf2ff;
		}
	}

	&Item {
		padding: $gap-xs $gap-s;

		&:hover {
			color: $blue5;
		}
	}

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: inline;
		}

		li + li {
			margin-left: $gap-16;

			@include lg {
				margin-left: $gap-s;
			}

			@include md {
				margin-left: $gap-xs;
			}
		}
	}
}

.userHeader {
	padding: 17px $gap-l;

	@include md {
		padding: 17px $gap-m;
	}
}

.dropdown {
	z-index: 2002;

	:global(.ant-dropdown-menu) {
		padding: $gap-9;
	}

	:global(.ant-dropdown-menu-title-content) {
		color: $grey-text;
		@include accent-s;
		padding: $gap-3 0;
	}

	:global(.ant-dropdown-menu-item-group-title) {
		padding: 0 !important;
		margin-bottom: $gap-xs;
		color: $blue-text !important;
		@include accent-s;
	}
}

.loginBtn {
	padding: $gap-m $gap-3xl;
	border-radius: $gap-9;
}

.account {
	padding: $gap-3 $gap-s;
	&Title,
	&Text {
		text-align: center;
	}

	&Title {
		color: $blue-text;
		margin-bottom: $gap-xs;

		@include accent-m;
		line-height: 1 !important;

		@include lg {
			@include accent-s;
		}
	}

	&Text {
		color: $grey-text;

		@include text-xs;
		line-height: 1 !important;
	}
}

.toggleMenu {
	position: absolute;
	width: min-content;
	left: 0;
	margin-left: $gap-s;
}

.actions {
	gap: $gap-l;

	a {
		text-wrap: nowrap;
	}

	@include lg {
		gap: $gap-m;
	}

	@include md {
		gap: $gap-xs;
	}
}

@media (max-width: 1390px) {
	.telephone {
		display: none;
	}
}
