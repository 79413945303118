@import 'src/styles/mixins';
@import 'src/styles/colors';
@import 'src/styles/typography';
@import 'src/styles/functions';
@import 'src/styles/gaps';

.container {
	height: 100%;
	width: 100%;
	background: $white;
	display: block;
	overflow: auto;

	position: fixed;
	top: 65px;
	padding-bottom: $gap-8xl;
	left: 0;
	z-index: 2002;
}

.link {
	width: 100%;
}

.info,
.item {
	padding: $gap-m $gap-l;
}
.info {
	background: $background-secondary;

	&Status,
	&Description,
	&Locale {
		color: $blue-text;
	}

	&Status,
	&Locale {
		@include text-s;
	}

	&Description {
		@include text-xs;
	}

	&Locale {
		background: $white;
		padding: $gap-xs $gap-9;
		border-radius: $gap-xs;
	}
}

.item {
	margin-top: $gap-3;
	background: $background-secondary;
	color: $blue-text;
	width: 100%;

	@include accent-l;
}

.telephone,
.email {
	@include accent-s;
}

.telephone {
	margin-top: $gap-xl;
	color: $blue5;
}

.email {
	margin-top: $gap-16;
	color: $footer-link;
}

.lkItem {
	background: $white;
	width: 100%;
	padding: $gap-9 $gap-l;

	@include text-m;
}

.icon {
	display: flex;
	align-items: center;
	justify-content: center;
}

.langSelect {
	background: $white;
}
