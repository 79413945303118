@import 'src/styles/mixins';
@import 'src/styles/gaps';

.container {
	max-width: 1400px;
	padding: 0 50px 0;
	width: 100%;
	margin: 0 auto;

	@include xs {
		width: 100%;
		padding: $gap-s;
	}
}

// TODO: styles for old page (find warehouses) need to refactor
.containerWarehouse {
	max-width: 120rem;
	width: 100%;
	margin: 0 auto;
	z-index: 2;

	@include xs {
		width: 100%;
		padding: 12px;
	}
}
